import React from "react";
import { PageLayout } from "../components/PageLayout";
import { Privacy } from "../components/Privacy";
import { privacyPageHeroImage } from "../images";

const PrivacyPage: React.FC = () => {
  return (
    <PageLayout
      heroBackgroundImage={`url(${privacyPageHeroImage})`}
      heroOverlayLeadElement="Privacy"
      heroOverlaySecondaryElement="Privacy"
    >
      <Privacy />
    </PageLayout>
  );
};

export default PrivacyPage;
