import React from "react";
import { Heading2 } from "./Heading2";
import { PageHeader } from "./PageHeader";
import { Paragraph } from "./Paragraph";
import { Section } from "./Section";

export const Privacy: React.FC = () => {
  return (
    <>
      <PageHeader primary="Privacy" secondary="Privacy" />
      <Section>
        <Heading2>Privacy</Heading2>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum eu
          fermentum arcu. Vivamus aliquet ut nulla sed maximus. Maecenas mattis
          enim ut justo gravida lobortis ut finibus lectus. Nullam vestibulum
          urna neque, elementum suscipit augue molestie ut. In eget posuere
          libero, cursus volutpat est. Aenean lacus nisi, molestie sit amet
          tempus in, aliquet vitae nisl. Suspendisse ex justo, hendrerit vitae
          molestie vitae, gravida eu tellus. Nam in elit eros. Nullam lobortis
          aliquet sem, ac scelerisque arcu pellentesque id.
        </Paragraph>
      </Section>
    </>
  );
};
